<template>
  <v-container fluid>
    <!-- <v-layout row wrap> -->
    <v-row justify="center" mb-4 mt-2>
      <v-col>
        <h1>Seleccione Cliente</h1>
        <!-- <h3>Asocia equipos a un Order Id</h3> -->
      </v-col>
    </v-row>
    <!-- </v-layout> -->
    <hr />
    <v-row justify="center">
      <v-col cols="6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <router-link to="/migps">
            <v-img
              src="../assets/migps.png"
              width="150"
              height="100"
              rel="stylesheet"
              href="/tbk"
            >
            </v-img>
          </router-link>
          <router-link to="/tbk">
            <v-img src="../assets/transbank.png" width="200" height="80">
            </v-img>
          </router-link> </v-form
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data: () => ({
    sendingRequest: false,
    alerta: false,
    alertaNegativa: false,
    alertaPdf: false,
    valid: false,
    loaderPdf: false,
    loadingTable: false,
    dialog: false,
    dialogoPdf: false,
    etiquetas: "",
    OrderId: "",
    cliente: "",
    clienteEtiqueta: "",
    tipoEtiqueta: "",
    tipoRules: [(v) => !!v || "Tipo de entrega es requerida"],
    clientesRules: [(v) => !!v || "Cliente es requerido"],
    OrderIdRules: [
      (v) => !!v || "Order Id es requerido",
      (v) => (v && v.length >= 5) || "OrderId debe ser mayor de 5 caracteres",
    ],
    regionesRule: [
      (v) => !!v || "Campo requerido",
      (v) => (v && v.length >= 6) || "Debes seleccionar una región",
    ],

    equipoTbk: "",
    equipoTbkRules: [(v) => !!v || "Se requiere código de equipo"],
    // select: 0,
    // items: [1, 2, 3, 4],
    nombreCampo: "added_kits",

    textoConfirmacion: "",
    color: "warning",
    statusLlamada: 0,
  }),

  methods: {
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    // validate() {
    //   this.$refs.form.validate();
    // },
    reset() {
      this.$refs.form.reset();
      this.equipoTbk, (this.OrderId = "");
      this.alerta = false;
      this.alertaNegativa = false;
    },
    async agregarEquipoTbk() {
      this.sendingRequest = true;
      try {
        console.log("Equipo TBK: " + this.equipoTbk);

        this.alerta = false;
        this.alertaNegativa = false;

        var orderId = this.OrderId;
        console.log("Order Id: " + orderId);
        const respuesta = await axios.get(
          `https://backendservices.rayoapp.com/Task-Groups/tasks_group/filters/by?idOrder=${orderId}`,
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        //console.log(respuesta.data[0].tasks[0]._id);
        for (const n in respuesta.data[0].tasks) {
          var idTarea = respuesta.data[0].tasks[n]._id;
          var orderId = respuesta.data[0].tasks[n].order_id_task;
          var equipos = this.equipoTbk;
          var equipoTbksSeparados = equipos.split(",");
          var arregloequipoTbks = [];
          for (let i = 0; i < equipoTbksSeparados.length; i++) {
            arregloequipoTbks[i] = equipoTbksSeparados[i];
          }
          // arregloequipoTbks[] = equipoTbksSeparados;
          console.log(arregloequipoTbks);

          var data = {
            field: this.nombreCampo,
            data: arregloequipoTbks,
            
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
          };

          const respuestaIdTarea = await axios.put(
            `https://backendservices.rayoapp.com/Tasks/tasks/${idTarea}/add_metadata`,
            data,
            {
              headers: {
                "warehouse.rayoapp.com": "rayo",
              },
            }
          );
          console.log("Status: " + respuestaIdTarea.status);
          this.statusLlamada = respuestaIdTarea.status;

          let newLabel = { orderID: orderId  
            ,
            
            emailResponsable: this.datosUsuario().email_user,
        nameResponsable: this.datosUsuario().name_user,
        uidResponsable: this.datosUsuario().uid,
        sistemaResponsable: "warehouse.rayoapp.com",
        epochResponsable: new Date().getTime()
          };
          console.log(newLabel);

          const respuestaEtiqueta = await axios.post(
            `https://southamerica-east1-rayo-api.cloudfunctions.net/funcionesRayo/funcionesRayo/GeneraEtiquetaTBKInst`,
            newLabel
          );

          if (this.statusLlamada === 200 && respuestaEtiqueta.status === 200) {
            this.alerta = true;
            this.sendingRequest = false;
          }
        }
      } catch (error) {
        this.alertaNegativa = false;
        console.log(error);
        this.alertaNegativa = true;
      }
      this.sendingRequest = false;
    },
  },
  computed: {
    deshabilitar() {
      return this.OrderId === "" || this.equipoTbk === "";
    },
    deshabilitarBotonPdf() {
      if (this.clienteEtiqueta == "5fb7baf4c8c6410667c3f1bb") {
        return this.clienteEtiqueta == "" || this.tipoEtiqueta == "";
      } else {
        return this.clienteEtiqueta == "";
      }
    },
  },
};
</script>

<style scoped>
a.etiquetas {
  text-decoration: none;
  color: white;
}
/* .pad {
  pa
} */
</style>
